import React from 'react';
import './index.scss';
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../../../utils/routing";
import { Title, Button, Paragraph, Card } from '@opsdti-global-component-library/amgen-design-system';

export const MoreIntelligence: React.FC = () => {
    const navigate = useNavigate();
    const currentRoute = useLocation();
  
    return (
        <div className="ext-more-intelligence">
          <Title level={2}>Explore More Intelligence Features</Title>
          <div className="ext-more-intelligence-container">
            <Card title="Peer Big Sheet">
              <>
                <div className="ext-page-description">
                  <Paragraph strong>Lorem ipsum Peer Big Sheet sit amet consectetur. Ut sed pretium accumsan tortor nisi a gravida laoreet tincidunt. Diam tortor sed quisque posuere.</Paragraph>
                </div>
                <div className="ext-page-image">
                  <img src={`${process.env.REACT_APP_DOMAIN}/PeerBigSheet.png`} alt="Peer Big Sheet Image" />
                </div>
                <div className="ext-row ext-page-navigation-wrapper">
                  <Button
                    text="Peer Big Sheet"
                    type="secondary"
                    className="ext-page-navigation-footer-btn"
                    onClick={() => {
                      navigate(`${BASE_ROUTE}/peer-big-sheet`, {
                        state: {
                          callingPath: currentRoute.pathname,
                        },
                      });
                    }}
                    />
                </div>
              </>
            </Card>
            <Card title="Oncology">
              <>
                <div className="ext-page-description">
                  <Paragraph strong>Lorem ipsum Oncology News sit amet consectetur. Ac ut nascetur nisl eget scelerisque quam ut pulvinar. Amet dignissim platea faucibus.</Paragraph>
                </div>
                <div className="ext-page-image">
                  <img src={`${process.env.REACT_APP_DOMAIN}/Oncology.png`} alt="Oncology Image" />
                </div>
                <div className="ext-row ext-page-navigation-wrapper">
                  <Button
                    text="Oncology"
                    type="secondary"
                    className="ext-page-navigation-footer-btn"
                    onClick={() => {
                      navigate(`${BASE_ROUTE}/ta/oncology`, {
                        state: {
                          callingPath: currentRoute.pathname,
                        },
                      });
                    }}
                    />
                </div>
              </>
            </Card>
            <Card title="Amgen Ventures">
              <>
                <div className="ext-page-description">
                  <Paragraph strong>Amgen Ventures dolor sit amet consectetur. Eleifend at neque praesent a eu nisi. Arcu vulputate sed magna adipiscing egestas nec aliquam vulputate.</Paragraph>
                </div>
                <div className="ext-page-image">
                  <img src={`${process.env.REACT_APP_DOMAIN}/AmgenVentures.png`} alt="Amgen Ventures Image" />
                </div>
                <div className="ext-row ext-page-navigation-wrapper">
                  <Button
                    text="Amgen Ventures"
                    type="secondary"
                    className="ext-page-navigation-footer-btn"
                    onClick={() => {
                      navigate(`${BASE_ROUTE}/amgen-ventures`, {
                        state: {
                          callingPath: currentRoute.pathname,
                        },
                      });
                    }}
                    />
                </div>
              </>
            </Card>
          </div>
      </div>
    );
  };
