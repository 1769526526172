export type FEEDBACK_FRAME = "THUMBS" | "COMMENTS" | "THANKS" | "ERROR"
type FEEDBACK_FRAME_KEY = {
    [key in FEEDBACK_FRAME]: {
        header: string;
        content: string;
    }

}
export const FEEDBACK_FRAME_CONSTS: FEEDBACK_FRAME_KEY = {
    "THUMBS": {
        header: "Feedback:",
        content: "Was this answer helpful?"
    },
    "COMMENTS": {
        header: "Thanks!",
        content: "Add any additional details below"
    },
    "THANKS": {
        header: "Thanks!",
        content: "Your feedback is appreciated"
    },
    "ERROR": {
        header: "",
        content: "The Q&A API is currently unreachable, please try again later"
    }
}

export const FEEDBACK_THUMBS_UP: number = 1 // DB accepts just an int
export const FEEDBACK_THUMBS_DOWN: number = 0 // DB accepts just an int