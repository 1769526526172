import './index.scss';
import { useState, useEffect, useContext, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { apiResponse } from '../../../../utils/commonMethods';
import { BASE_ROUTE } from "../../../../utils/routing";
import { Button, Card, Tag, Title, ThemeContext } from '@opsdti-global-component-library/amgen-design-system';
import { NewsArticle, NewsTabs, NewsTab } from '../../consts';
import { NewsList } from './news-list';
import dayjs from "../../../../utils/dayjs";
import { AiSparkle } from '../../../../assets/icons/ai-sparkle';
import { AppContext } from '../../../../utils/app-context';
import { apiErrorMessage, noDataMessage } from '../../../../assets/errorMessageConstants';

type Props = {
  title: string;
  tabs: NewsTabs;
}

const TODAY = dayjs().format('YYYY-MM-DD');
const THREE_MONTHS_AGO = dayjs().subtract(3, 'month').format('YYYY-MM-DD');

const PAGE_LIMIT = 5;
export const GPTNews = ({title, tabs}: Props) => {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { colorPrimaryBg, colorInfo } = theme?.token as any;
  const [activeTab, setActiveTab] = useState<NewsTab | undefined>(tabs[0]);
  const [newsList, setNewsList] = useState<NewsArticle[]>([]);
  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(false);
  const [isNewsListError, setIsNewsListError] = useState<string>('');
  const { authData } = useContext(AppContext);

  const tabsWithChildren = useMemo(() => {
    return tabs.map((tab, index) => {
      if(tab.label === 'Oncology' && !authData?.externalAuthorization?.oncology) return null
      return {
        ...tab,
        children: <NewsList key={index} news={newsList} isNewsError={isNewsListError} isNewsLoading={isNewsListLoading} />
      }
    })
    .filter((n) => n) // Remove null element if no oncology
  }, [newsList,isNewsListLoading])

  const getNews = async () => {
    const payload = {
        sort_by_value: "desc",
        page_offset:  0,
        limit: PAGE_LIMIT,
        start_date: THREE_MONTHS_AGO,
        end_date: TODAY,
        filter_date_on: 'published',
        filters: activeTab?.filters,
      };
  
      try {
        const endpoint = activeTab?.key === 'Oncology' ? 'get-apoc-news' : 'get-news-with-tags';
        const res = await apiResponse("post", endpoint, [], payload);
        if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
          setNewsList(res.data.data.response);
        } else {
          setIsNewsListError(noDataMessage)
          setNewsList([]);
        }
        setIsNewsListLoading(false);
      } catch (error) {
        console.log('error fetching news for', error, activeTab?.filters)
        setNewsList([]);
        setIsNewsListError(apiErrorMessage);
        setIsNewsListLoading(false);
      }
  }

  useEffect(() => {
    if(activeTab?.filters){
      setIsNewsListLoading(true)
      getNews();
    }
  }, [activeTab])

  return (
      <div className="ext-gpt-news">
        <Card 
          divider={false}
          title={
            <div className='gpt-news-header'>
              <Title level={3}>{title}</Title>
              <Tag
                style={{ backgroundColor: colorPrimaryBg, color: colorInfo }}
                text='AI GENERATED' 
                rootClassName='gpt-news-header-tag-root'
                icon={<AiSparkle width={12} height={12} />}
              />
            </div>
          } 
          tabList={tabsWithChildren} 
          defaultActiveTabKey={activeTab ? activeTab.key : tabs[0].key}
          onTabChange={(key: string) => setActiveTab(tabs.find(o => o.key === key))}
          footer={
            <div className='gpt-news-view-more-btn-container'>
              <Button
              text="View More News Articles"
              type="secondary"
              className='gpt-news-view-more-btn'
              onClick={() => {
                navigate(`${BASE_ROUTE}/news-search`);
              }}
            />
            </div>
          }
          />
    </div>
  );
};
