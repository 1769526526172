import React from "react";
import "./index.scss";
import { ThumbsUpIcon, ThumbsDownIcon } from '@opsdti-global-component-library/amgen-design-system';
import { FEEDBACK_FRAME, FEEDBACK_FRAME_CONSTS, FEEDBACK_THUMBS_DOWN, FEEDBACK_THUMBS_UP } from "./consts";
import { getQnaApiUrl } from "../../api/api-config";
import axios from "axios";
import { getOktaToken } from "../../utils/commonMethods";

type Props = {
    questionId: string;
}

const QnaFeedback: React.FC<Props> = (props: Props) => {
    const { questionId } = props;
    const [selectedFrame, setSelectedFrame] = React.useState<FEEDBACK_FRAME>('THUMBS');
    const [userFeedbackComments, setUserFeedbackComments] = React.useState<string>('');
    const [userFeedbackRating, setUserFeedbackRating] = React.useState<number>(-1); // Negative one should never appear in DB due to page flow. Select thumbs calls feedbackSubmit


    const oktaToken = getOktaToken();
    const onFeedbackSubmit = async(nextFrame: FEEDBACK_FRAME, selectedUserFeedbackRating: number) => {
        try {
            const res = await axios({
              method: "post",
              url: `${getQnaApiUrl("qna-question-feedback")}`,
              data: {
                "id": questionId,
                "user_feedback_rating": selectedUserFeedbackRating,
                "user_feedback_comment": userFeedbackComments
              },
              headers: {
                Authorization: `Bearer ${oktaToken}`,
              },
            });
            if (res.data.status_code === 200) {
                setSelectedFrame(nextFrame);
            } else {
                setSelectedFrame('ERROR')
            }
        } catch (error) {
            setSelectedFrame('ERROR')
        }
    }

    const onThumbsSubmit = (selectedUserFeedbackRating: number) => {
        setUserFeedbackRating(selectedUserFeedbackRating)
        onFeedbackSubmit('COMMENTS', selectedUserFeedbackRating)
    }

    return ( 
        <div className={`ext-qna-feedback ${selectedFrame === "COMMENTS" ? 'ext-qna-feedback-full' : '' }`}>
            <div className="header-container"> 
                <div className="header-container-text">
                    <span className="header-text">{FEEDBACK_FRAME_CONSTS[selectedFrame].header}&nbsp;</span>
                    {FEEDBACK_FRAME_CONSTS[selectedFrame].content}
                </div>
                {selectedFrame === "THUMBS" 
                ?   <div className="thumbs-container">
                        <div className="thumbs up">
                            <ThumbsUpIcon height={16} width={16} onClick={() => onThumbsSubmit(FEEDBACK_THUMBS_UP)} />
                        </div>
                        <div className="thumbs down">
                            <ThumbsDownIcon height={16} width={16} onClick={() => onThumbsSubmit(FEEDBACK_THUMBS_DOWN)} />
                        </div>
                    </div>
                :   null}
            </div>
            {selectedFrame === "COMMENTS" 
                ?   <div className="comments-container ops-mt-2">
                        <textarea onChange={(e) => setUserFeedbackComments(e.target.value)} className="comments-text-area" placeholder="Enter additional feedback here" />
                        <div className="submit-button-container ops-mt-2">
                            <div className="submit-text" onClick={() => setSelectedFrame('THANKS')}>CLOSE</div>    
                            <div className="submit-text" onClick={() => onFeedbackSubmit('THANKS', userFeedbackRating)}>SUBMIT FEEDBACK</div>    
                        </div>
                    </div>
                :   null
            }
        </div>
    )
}

export default QnaFeedback


