import { useState, useEffect } from "react";
import Card from "../components/card";
import InitialView from "./initial-view"
import AnswerView from "./answer-view"
import "./index.scss";
import { RADIO_OPTIONS } from "./consts";
import axios from "axios";
import { getApiUrl } from "../api/api-config";

import { authorizationApi } from "../utils/auth-config";
import { getOktaToken } from "../utils/commonMethods";

const initialRadioPermissions = {
    financeQna: false,
    externalQna: false,
    supplyQna: false,
    pipelineQna: false,
    brandQna: false,
    manufacturingQna: false,
    peopleQna: false
}

const QATile = () => {
    const [selectedDomain, setSelectedDomain] = useState<string>('')
    const [question, setQuestion] = useState<string>('')
    const [answer, setAnswer] = useState<string>('')
    const [answerLoading, setAnswerLoading] = useState<boolean>(false)
    const [initialView, setInitialView] = useState<boolean>(true)
    const [radioOptions, setRadioOptions] = useState<Array<string>>([])
    const [questionId, setQuestionId] = useState<string | undefined>(undefined)
    const [{ financeQna, externalQna, supplyQna, pipelineQna, brandQna, manufacturingQna, peopleQna }, setRadioOptionsPermissions] = useState(initialRadioPermissions)

    // Auth
    const oktaToken = getOktaToken();
    const { data:authData, error: authError, isLoading: authIsLoading } = authorizationApi(false);

    useEffect(() => {
        if (authData?.sensingAuthorization) {
            const { financeQna, externalQna, supplyQna, pipelineQna, brandQna, manufacturingQna } = authData?.sensingAuthorization?.externalAuthorization
            setRadioOptionsPermissions({ financeQna, externalQna, supplyQna, pipelineQna, brandQna, manufacturingQna, peopleQna})
        }
      }, [authData]);

    // Setup Array of Radio Button Options based on permissions
    useEffect(() => {
        const checkPerms = (domain: string) => {
            if(domain === "News") return externalQna
            if(domain === "Supply") return supplyQna
            if(domain === "Finance") return financeQna
            if(domain === "Pipeline") return pipelineQna
            if(domain === "Brand") return brandQna
            if(domain === "Manufacturing") return manufacturingQna
            if(domain === "People") return peopleQna
            return false
        }
        const radioOptionsFiltered = Object.keys(RADIO_OPTIONS).filter((domain) => checkPerms(domain))
        setRadioOptions(radioOptionsFiltered)
    }, [financeQna, externalQna, supplyQna, pipelineQna, brandQna, manufacturingQna, peopleQna])

    const onQuestionSubmit = async(question: string) => {
        setQuestion(question)
        setAnswerLoading(true)
        setInitialView(false)
        setQuestionId(undefined)
        try {
            const qnaUrl =  "unified-qna-question" ;
            let requestBody: { question: string, domain?: string } = {
                "question": question,
                "domain": selectedDomain === 'News' ? 'external' : selectedDomain.toLowerCase()
            };

            delete requestBody.domain;      

            const res = await axios({
              method: "post",
              url: `${getApiUrl(qnaUrl)}`,
              data: requestBody,
              headers: {
                Authorization: `Bearer ${oktaToken}`,
              },
            });
            if (res?.data?.data) {
                setQuestionId(res.data.data.id);
                setAnswer(res.data.data.answer);
            }
            setAnswerLoading(false)
        } catch (error) {
            setAnswer("The Q&A API encountered an error while trying to answer your question. Please rephrase your question or try again later.");
            setAnswerLoading(false)
        }
    }

    if  ((authData && authError === null && !authIsLoading) &&
            (authData.sensingAuthorization?.homeAuthorization?.experimentalQna) && 
            (financeQna || externalQna || supplyQna || pipelineQna || brandQna || manufacturingQna || peopleQna)
        ){
        return (
            <div className="qa-tile">
                <Card
                    cardHeader="Sensing Q&A"
                    cardHeaderTag="Experimental"
                    isTooltip
                    tooltipText="This Q&A capability is powered by &#13;&#10;
                                  OpenAI, so it comes with all of the &#13;&#10;
                                  attendant benefits and limitations. As such, this &#13;&#10;
                                  output is for illustrative purposes and not yet &#13;&#10;
                                  suitable for decision making."
                    tooltipTag="Experimental"
                >
                    <div className="content-container">
                        {initialView 
                        ?   <InitialView
                                radioOptions={radioOptions}
                                selectedDomain={selectedDomain}
                                setSelectedDomain={setSelectedDomain} 
                                onQuestionSubmit={onQuestionSubmit}
                            />
                        :   <AnswerView
                                questionId={questionId}
                                domain={selectedDomain} 
                                setInitialView={setInitialView}
                                question={question}
                                answer={answer}
                                answerLoading={answerLoading}
                            />
                        }
                    </div>
                </Card>
            </div>
        )
    }
      

    return <></>
};

export default QATile;
