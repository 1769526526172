export type NewsTab = {
  key: string;
  label: string;
  filters: {};
  children: JSX.Element;
}
export type NewsTabs = NewsTab[];

export type Company = {
  name: string;
  ticker: string;
  id: string;
}
export const GPT_NEWS_TABS: NewsTabs = [{
  key: 'All',
  label: 'All',
  filters: {
    apoc_company: [],
    apoc_product: [],
    disease_area: [],
    institution: [],
    modality: [],
    target: [],
    phase: []
  },
  children: <div />
}, {
  key: 'Obesity',
  label: 'Obesity',
  filters: {
    corex_topic: ["Obesity"],
  },
  children: <div />
},
{
  key: 'Oncology',
  label: 'Oncology',
  filters: {
    apoc_company: [],
    apoc_product: [],
    disease_area: [],
    institution: [],
    modality: [],
    target: [],
    phase: []
  },
  children: <div />
},
{
  key: 'Inflammatory',
  label: 'Inflammatory',
  filters: {
    corex_topic: ["Therapeutic Area - Inflammatory Diseases"]
  },
  children: <div />
}, {
  key: 'Cardiovascular',
  label: 'Cardiovascular',
  filters: {
    corex_topic: ["Therapeutic Area - Cardiovascular Diseases"]
  },
  children: <div />
}, {
  key: 'Rare Diseases',
  label: 'Rare Diseases',
  filters: {
    corex_topic: ["Rare Disease"]
  },
  children: <div />
}]

export const GPT_COMPETITOR_NEWS_TABS_DEFAULT: NewsTabs = [{
  key: 'All',
  label: 'All',
  filters: {
    company: [],
  },
  children: <div />
},
{
  key: "AbbVie",
  label: "AbbVie",
  filters: { company: ["ABBV"] },
  children: <div />
},
{
  key: "Amgen",
  label: "Amgen",
  filters: { company: ["AMGN"] },
  children: <div />
},
{
  key: "AstraZeneca",
  label: "AstraZeneca",
  filters: { company: ["AZN"] },
  children: <div />
},
{
  key: "Biogen",
  label: "Biogen",
  filters: { company: ["BIIB"] },
  children: <div />
},
{
  key: "Bristol-Myers",
  label: "Bristol-Myers",
  filters: { company: ["BMY"] },
  children: <div />
},
{
  key: "Eli Lilly",
  label: "Eli Lilly",
  filters: { company: ["LLY"] },
  children: <div />
},
{
  key: "Gilead",
  label: "Gilead",
  filters: { company: ["GILD"] },
  children: <div />
},
{
  key: "GSK",
  label: "GSK",
  filters: { company: ["GSK"] },
  children: <div />
},
{
  key: "Johnson & Johnson",
  label: "Johnson & Johnson",
  filters: { company: ["JNJ"] },
  children: <div />
},
{
  key: "Merck",
  label: "Merck",
  filters: { company: ["MRK"] },
  children: <div />
},
{
  key: "Novartis",
  label: "Novartis",
  filters: { company: ["NVS"] },
  children: <div />
},
{
  key: "Novo Nordisk",
  label: "Novo Nordisk",
  filters: { company: ["NVO"] },
  children: <div />
},
{
  key: "Pfizer",
  label: "Pfizer",
  filters: { company: ["PFE"] },
  children: <div />
},
{
  key: "Regeneron",
  label: "Regeneron",
  filters: { company: ["REGN"] },
  children: <div />
},
{
  key: "Roche / Genentech",
  label: "Roche / Genentech",
  filters: { company: ["RHHBY"] },
  children: <div />
},
{
  key: "Sanofi",
  label: "Sanofi",
  filters: { company: ["SNY"] },
  children: <div />
},
{
  key: "Takeda",
  label: "Takeda",
  filters: { company: ["TAK"] },
  children: <div />
}

]
export type NewsArticle = {
  "article_id": string;
  "article_summary": string;
  "url": string;
  "source_id": string;
  "source_name": string;
  "publisher_name": string;
  "publisher_id": string;
  "content": string;
  "title": string;
  "published": string;
  "date_ingested": string;
  "topic_names": null | string;
  "corex_topic_names": null | string[];
  "company_names": null | string[];
  "product_names": null | string[];
  "location_names": null | string[];
  "company_tickers": null | string[];
}