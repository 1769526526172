import { useState, useLayoutEffect } from "react";

export const useIsOverflow = (ref: any, deps: any) => {
    const [isOverflow, setIsOverflow] = useState<boolean>(false);
  
    useLayoutEffect(() => {
      const { current } = ref;
  
      const trigger = () => {
        const hasOverflow = current.scrollHeight > current.clientHeight;
  
        setIsOverflow(hasOverflow);
        };
  
      if (current) {
        trigger();
      }
    }, [ref, [...deps]]);
  
    return isOverflow;
};