import { useState, useEffect, useContext } from "react";
import WordCloud from "../visx-word-cloud/wordcloud";
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import {Word} from "../visx-word-cloud/wordcloud";
import {
  wordCloudNoDataMessage,
  apiErrorMessage,
} from "../../assets/errorMessageConstants";
import "./index.scss";
import { AppContext } from "../../utils/app-context";
import Card from "../card";
import { TimeScaleData } from "../../api/get-year-and-quarter-list-dt";
import { EarningsReport } from "../../api/get-earnings-word-cloud-dt";
import { useParentSize } from '@visx/responsive';
import EarningsDropdown from "../earnings-dropdown";

interface EarningsWordCloudProps {
  timeScaleData: TimeScaleData;
}

const wordCategories = {
  "Positive": { name: "Positive", color: "#0063C3", lightColor: "#E5EFF9"},
  "Neutral":  { name: "Neutral",  color: "#7E7E7E", lightColor: "#F3F3F3"},
  "Negative": { name: "Negative", color: "#FF6720", lightColor: "#FFF0E9"},
}

function EarningsWordCloud({timeScaleData}: EarningsWordCloudProps) {
  const [preWordCloudData, setPreWordCloudData] = useState<Word[]>([]);
  const [postWordCloudData, setPostWordCloudData] = useState<Word[]>([]);
  const [selectedYear, setSelectedYear] = useState(Object.keys(timeScaleData)[1]);
  const [selectedQuarter, setSelectedQuarter] = useState<string>(timeScaleData[selectedYear][0]);
  const [wordCloudErrorMessage, setWordCloudErrorMessage] = useState("");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [wordCloudTabs, setwordCloudTabs] = useState([
    { name: "Pre-Earnings", value: true },
    { name: "Post-Earnings", value: false },
  ]);
  const { parentRef: wordCloudRef, width: wordCloudWidth } = useParentSize({ debounceTime: 150 });

  const getCategory = (sentiment: number): string => {
    if (sentiment > 0.33) return "Positive";
    if (sentiment < -0.33) return "Negative";

    return "Neutral"
  }

  const oktaToken = getOktaToken();
  const fetchWordCloudData = async () => {
    setIsDataLoading(true);
    setWordCloudErrorMessage("");
    try {
      const quarterNum = Number(selectedQuarter.slice(1))
      if(Number.isNaN(quarterNum)) return;
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-earnings-word-cloud")}?year=${selectedYear}&quarter=${quarterNum}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS") {
        const transformAPIResponseToWords = (arr: Array<EarningsReport> | null) => {
          return arr?.map((item: EarningsReport) => ({
            "text": item.keyword,
            "value": item.frequency_score,
            "category": getCategory(item.sentiment_score),
            "detail": item.detail
          })) || []
        }
        
        setPreWordCloudData(transformAPIResponseToWords(res.data.data.pre))
        setPostWordCloudData(transformAPIResponseToWords(res.data.data.post))

      } else {
        setPreWordCloudData([]);
        setPostWordCloudData([]);
        setWordCloudErrorMessage(wordCloudNoDataMessage);
      }
      setIsDataLoading(false);
    } catch (error) {
      setPreWordCloudData([]);
      setPostWordCloudData([]);
      setIsDataLoading(false);
      setWordCloudErrorMessage(apiErrorMessage);
    }
  };

  const { authData } = useContext(AppContext);
  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.earnings
    ) {
      fetchWordCloudData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuarter, selectedYear]);

  const handleDurationTabs = (selectedView: any) => {
    const updatedDurationViews = wordCloudTabs.map((view: any) => {
      if (view.name === selectedView.name) {
        view.value = true;
      } else {
        view.value = false;
      }
      return view;
    });
    setwordCloudTabs([...updatedDurationViews]);
  };

  return (
    <Card cardHeader="Sentiment Wordcloud">
      <div className="ext-word-cloud">
        <div className="navbar-container ext-navbar-width">
          <ul className="nav navbar-text ext-cursor-pointer ext-align-item-inline ops-pt-2">
            {wordCloudTabs.map((view) => {
              return (
                <li
                  key={view.name}
                  className={`nav-item ops-px-4 ops-pb-2 ${view.value ? "active" : "inactive"}`}
                  onClick={() => handleDurationTabs(view)}
                >
                  {view.name}
                </li>
              );
            })}
          </ul>
          <div className="ext-earnings-date-dropdown-container">
            <EarningsDropdown 
              label="YEAR"
              options={Object.keys(timeScaleData).reverse()}
              selectedValue={selectedYear}
              onMenuItemClick={ (event)=> {
                setSelectedYear(event?.key);
                setSelectedQuarter(timeScaleData[event?.key][0])
              }}
            />
            <EarningsDropdown
              label="TIMEFRAME"
              options={timeScaleData[selectedYear]}
              selectedValue={selectedQuarter}
              onMenuItemClick={ (event)=> {
                setSelectedQuarter(event?.key);
              }}
            />
          </div>
        </div>
        <div ref={wordCloudRef} id="word-cloud" className="ops-pt-2 ext-wordcloud-wrapper">
          {wordCloudTabs[0].value && (
              <WordCloud
                isLoading={isDataLoading}
                errorMessage={wordCloudErrorMessage}
                rotationRange={[0, 0]}
                fontSizes={[15, 40]}
                fontFamily="Inter"
                width={wordCloudWidth} // Scaling w/ bootstrap grid
                height={250}
                categories={wordCategories}
                showLegend={true}
                words={preWordCloudData}
                spiralType="archimedean"
                padding={2}
                deterministic={true}
                bold={true}
              />
            )}
          {wordCloudTabs[1].value && (
              <WordCloud
                isLoading={isDataLoading}
                errorMessage={wordCloudErrorMessage}
                rotationRange={[0,0]}
                fontSizes={[15, 40]}
                fontFamily="Inter"
                width={wordCloudWidth} // Scaling w/ bootstrap grid
                height={250}
                categories={wordCategories}
                showLegend={true}
                words={postWordCloudData}
                spiralType="archimedean"
                padding={2}
                deterministic={true}
                bold={true}
              />
            )}
          </div>
      </div>
    </Card>
  );
};

export default EarningsWordCloud;
