interface Props {
    fill?: string;
    classname?: string;
    width: number;
    height: number;
  }
  
export const AiSparkle = ({ fill, classname, width, height }: Props) => {
  return (
    <svg className={classname} width={width} height={height} viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.65869 5.95174L4.54492 3.29305H5.08877L5.975 5.95174L8.63369 6.83797V7.38181L5.975 8.26805L5.08877 10.9267H4.54492L3.65869 8.26805L1 7.38181V6.83797L3.65869 5.95174Z" fill="#0063C3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67474 2.36885L9.13102 1H9.67486L10.1311 2.36885L11.5 2.82514V3.36898L10.1311 3.82526L9.67486 5.19412H9.13102L8.67474 3.82526L7.30588 3.36898V2.82514L8.67474 2.36885Z" fill="#0063C3"/>
    </svg>
  )
}
