import { useState, Dispatch, SetStateAction, useEffect } from "react";
import "./index.scss";
import { RADIO_OPTIONS } from "./consts";
import ArrowIcon from "../assets/icons/arrow-icon";
import { Button } from "@opsdti-global-component-library/amgen-design-system";

interface InitialViewProps {
    onQuestionSubmit(question: string): void;
    selectedDomain: string;
    radioOptions: Array<string>;
    setSelectedDomain:  Dispatch<SetStateAction<string>>;
}

const InitialView = (props: InitialViewProps) => {
    const { onQuestionSubmit, setSelectedDomain, selectedDomain, radioOptions } = props
    const [question, setQuestion] = useState<string>("")
    const [selectedQuestions, setSelectedQuestions] = useState<Array<string>>([])

    const randomizSampleQuestions = () => {
        // Function that selects a random question from 3 random domains
        const keys = Object.keys(RADIO_OPTIONS);
        const randomKeys = [];

        // Select 3 random domains
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(Math.random() * keys.length);
            randomKeys.push(keys[randomIndex]);
            keys.splice(randomIndex, 1);
        }

        // Select a random question from each domain
        const randomQuestions = randomKeys.map(key => {
            const domainQuestions = RADIO_OPTIONS[key];
            const randomIndex = Math.floor(Math.random() * domainQuestions.length);
            return domainQuestions[randomIndex];
        });

        setSelectedQuestions(randomQuestions)
    }

    useEffect(() => {
        selectedDomain
            ? setSelectedQuestions(RADIO_OPTIONS[selectedDomain])
            : randomizSampleQuestions()
    }, [selectedDomain])

    return (
        <div>
            <div className="qa-text">
                This capability is limited to answering questions by individual domains, 
                using data & metrics visible on Sensing. 
                {selectedDomain && "  Choose a domain, then enter a question."}
            </div>
            <div className="ext-row ops-px-3">
                {selectedDomain && radioOptions.map((label: string) => {
                    return (
                        <span className="radio-container ext-col-5 ops-pl-1 ops-pt-2" key={label}>
                            <input
                                type="radio"
                                value={label}
                                className="radio-button"
                                checked={selectedDomain === label}
                                onChange={(e) => setSelectedDomain(e.target.value as string)}
                            />
                            <span className="ops-ms-4">{label}</span>
                        </span>
                    );
                })}
            </div>
            <div className="ops-mt-5 ops-mb-1 question-header"> 
                ENTER YOUR QUESTION
            </div>
            <textarea
                rows={5}
                value={question}
                className="question-text-area ops-mt-1"
                placeholder="Type your question here"
                onChange={(e) => setQuestion(e.target.value)}
            />
            <Button
                text="SUBMIT QUESTION"
                type="secondary"
                block
                disabled={question === ""}
                onClick={() => onQuestionSubmit(question)}
                className="submit-button"
            />
            <div className="ops-mt-5 ops-mb-1 question-header"> 
                SAMPLE QUESTIONS
            </div>
            {selectedQuestions.map((question: string, index) => {
                return (
                    <div
                    className='question-row-container ops-mb-1'
                    key={index}
                    >
                        <div className="question-row-question ops-ps-2 ops-py-1">
                            {question}
                        </div>
                        <div 
                            className="question-row-submit ops-my-2 ops-mx-4"   
                            onClick={() => onQuestionSubmit(question)}
                        >
                            <ArrowIcon />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default InitialView;
