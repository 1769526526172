import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en-gb'
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

export { Dayjs } from "dayjs"
export default dayjs
