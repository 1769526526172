import React, { useState, useEffect } from "react";
import './index.scss';
import { Card, BarChart} from '@opsdti-global-component-library/amgen-design-system';
import axios from "axios";
import { getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";
import { noDataMessage, /*apiErrorMessage*/ } from "../../../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";

type ComparativePEValuesProp = {
    popOverContent?: string;
};




export const ComparativePEValues: React.FC<ComparativePEValuesProp> = (props: ComparativePEValuesProp) => {
    type ItemType = {
        pe: number;
        company: string;
    };
 
    const [data, setData] = useState<ItemType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState("");
    const oktaToken = getOktaToken();

    const getBenchmarkPEValuesData = async () => {
    
        try {
            const res = await axios({
                method: "get",
                url: `${getApiUrl("get-comparative-pe-data")}`,
                headers: {
                    Authorization: `Bearer ${oktaToken}`,
                },
            });
            if (res?.data?.status === "SUCCESS") {
               setData(res.data.data);
            } else {
                setIsError(noDataMessage);
            }
        } catch (error) {
            // setIsError(apiErrorMessage);
            setData([{pe: 13.2, company: "Amgen"}, {pe: 8.4, company: "S&P 500"}, {pe: 6.7, company: "Peer Average"}]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getBenchmarkPEValuesData();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>{isError}</div>;
    }
    const dataKeys = [{barKey: 'npv',barLabel: 'label'}]

    const getBarLabelColor =(benchmark_label: string) => {
        if (benchmark_label === "Amgen") {
            return ["AMGEN", "#0063C3"];
        } else if (benchmark_label === "S&P 500") {
            return ["S&P 500", "#FDB81E"];
        } else{
            return ["PEERSET-AVERAGE", "#2FBCB6"];
        }
    }



    const maxPE = Math.max(...data.map(item => item.pe));

    const yAxisTicks = Math.ceil(maxPE/5) + 1 ;
    const yAxisDomain = [0, Math.ceil(maxPE/5) * 5];


    const dataSet = data.map((item: any, index: number) => {
        const [bar_label, bar_color] = getBarLabelColor(item.company);

        return {
            label: `${item.pe.toLocaleString()}X`,
            npv: item.pe,
            benchmark_label: bar_label,
            color: bar_color
        }
          
    });
    const popOverContent = (props.popOverContent)? props.popOverContent :
                           "Peerset Average includes all competitors except Eli Lilly, Novo Nordisk, and Takeda.";
    const cardTitle= "Comparative PE Multiple Benchmark";

    return (
        <div className="ext-intelligence-comparative-pe">
            <div className="ext-intelligence-comparative-pe-container">
                <Card title={<Card.Title  popoverContent={popOverContent} popoverIconColor="secondary"  title={cardTitle}/>}>
                
                    <BarChart  data={dataSet} xKey="benchmark_label" yAxisLabel="PE MULTIPLE" yAxisDomain={yAxisDomain} yAxisTickCount={yAxisTicks}
                     dataKeys={dataKeys} yAxisTickFormatter={(value) => `${value.toLocaleString()}X`} showMultiLineXAxisTick/>

                </Card>
            </div>
        </div>
    );
};


