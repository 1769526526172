import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/app-context";
import { useNavManager } from "../../hooks/use-nav-manager";

import "./index.scss";
import { Breadcrumb } from "@opsdti-global-component-library/amgen-design-system"
import { ExternalMegaMenuUtility, IMegaMenuSection } from "../../utility-module/external-mega-menu-utility";

const PageLayout = (props: any) => {
  const [selectedPage, setSelectedPage] = useState<string>("Trending");
  const [navDropdowns, setNavDropdowns] = useState<any>([]);
  const location: any = useLocation();
  const { authData } = useContext(AppContext);

  useEffect(() => {
    if(authData){
      const megaMenuUtility = new ExternalMegaMenuUtility();
      const navSections = megaMenuUtility.generateMegaMenuSections(authData);
      const navSectionsToDropdown = navSections.map((section: IMegaMenuSection, i) => {
        return {
          children: section.links.map((link, j) => {
            return {
              key: `${i}-${j}`,
              label: <Link to={link.url}>{link.label}</Link>,
              labelOnly: link.label,
              href: link.url,
              pageHref: link.url.split('/')[2] // 2 for /external/news-search --> news
            }
          }),
          key: section.sectionLabel,
          label: section.sectionLabel,
          type: 'group'
        }
      })
      setNavDropdowns(navSectionsToDropdown);
    }
  },[authData])

  const findLabel = () => {
    let label = 'Trending';
    navDropdowns.forEach((section: any) => {
      section.children.forEach((row: any) => {
        const pathNamePageHref = location.pathname.split('/')[2];
        if(row.pageHref === pathNamePageHref){
          label = row.labelOnly;
        }
      })
    });
    return label;
  
  }
  useEffect(() => {
    const label = findLabel()
    setSelectedPage(label);
  }, [location.pathname, navDropdowns]);

  useEffect(() => {
    const label = findLabel()
    registerTabMatomo(label)
  }, [location.pathname.split('/')[1], location.pathname.split('/')[2]])

  const registerTabMatomo = (tabName: string) => {
    if ('_paq' in window && window._paq !== undefined && window._paq !== null) {
      const matamoWindow: any = window;
      const _paq = matamoWindow._paq || [];
      _paq.push(['trackPageView', 'Tab-' + tabName]);
    }
  }

  let dowJones: boolean = false;

  let hideMainNavbar = false
  let hideSideNavbar = false
  const params = window.location.href.split("news/");
  if (params[1] !== undefined && params[1] !== "") {
    dowJones = true;
    hideMainNavbar = true
    hideSideNavbar = true
  }
  useNavManager(authData, hideMainNavbar, hideSideNavbar)

  return (
    <div className="ops-my-5">
      {authData && !dowJones && (
          <Breadcrumb
            items={[
              {
                title: 'Intelligence'
              },
              {
                menu: {
                  items: navDropdowns
                },
                title: selectedPage
              }
            ]}
          />
        )}
      <div>{props.children}</div>
    </div>
  );
};

export default PageLayout;
