const RefreshIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76338 2.23329C8.79671 1.26663 7.47004 0.666626 5.99671 0.666626C3.05004 0.666626 0.670044 3.05329 0.670044 5.99996C0.670044 8.94663 3.05004 11.3333 5.99671 11.3333C8.48338 11.3333 10.5567 9.63329 11.15 7.33329H9.76338C9.21671 8.88663 7.73671 9.99996 5.99671 9.99996C3.79004 9.99996 1.99671 8.20663 1.99671 5.99996C1.99671 3.79329 3.79004 1.99996 5.99671 1.99996C7.10338 1.99996 8.09004 2.45996 8.81004 3.18663L6.66338 5.33329H11.33V0.666626L9.76338 2.23329Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default RefreshIcon;
