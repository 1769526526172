import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import CompanyList from "./CompanyList";
import {
  CompaniesListRequest,
// eslint-disable-next-line import/no-unresolved
} from "../../api/get-companies-dt";
import { orderBy } from "lodash";
import {
  apiResponse,
} from "../../utils/commonMethods";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import ErrorMessage from "../../components/error-message";
import { apiErrorMessage } from "../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";
import RefreshIcon from "../../assets/icons/refresh-icon";
import "../../assets/css/bootstrap.scss";

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: "Company",
      key: "company_name",
      isSorting: true,
      type: "string",
      sort: {
        active: true,
        isSorted: true,
        direction: "asc",
        value: "company_name",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Price",
      key: "company_price",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Today",
      key: "company_daily_change_percentage",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Price History",
      key: "company_price_history",
      isSorting: false,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "52 Week range",
      key: "company_yearly_range.current_value",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "P/E Ratio",
      key: "company_pe_ratio",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Market Cap",
      key: "company_mkt_cap",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
  ]);

  const [pEMin, setPEMin] = useState("");
  const [pEMax, setPEMax] = useState("");
  const [mrMin, setMrMin] = useState("");
  const [mrMax, setMrMax] = useState("");
  const handleReset = () => {
    setPEMin("");
    setPEMax("");
    setMrMin("");
    setMrMax("");
    setFilteredCompanies(companies)
  };

  const [isRefreshed, setIsRefreshed] = useState(false);
  const [errorMessage, setErrorMeassage] = useState("");

  const sortTableData = (
    selectedIndex: number,
    companiesList:  any
  ) => {
    let updatedColumn = [...columns];
    const tempCompanyList = [...companiesList];
    updatedColumn = updatedColumn.map((column, index) => {
      if (index === selectedIndex) {
        let sortedTableData = [];
        column.sort.isSorted = true;
        column.sort.active = true;
        if (column.sort.direction === "asc") {
          column.sort.direction = "desc";
          if (column.type === "string") {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["desc"]);
          } else {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["desc"]);
          }
        } else {
          column.sort.direction = "asc";
          if (column.type === "string") {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["asc"]);
          } else {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["asc"]);
          }
        }
        setFilteredCompanies([...sortedTableData])
      } else {
        column.sort.isSorted = false;
        column.sort.direction = "asc";
        column.sort.active = false;
      }
      return column;
    });
    setColumns([...updatedColumn]);
  };

  useEffect(() => {
    let mutableCompanies = companies
    if(pEMin !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_pe_ratio >= pEMin)
    if(pEMax !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_pe_ratio <= pEMax)
    if(mrMin !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_mkt_cap >= mrMin)
    if(mrMax !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_mkt_cap <= mrMax)

    setFilteredCompanies(mutableCompanies)
  }, [pEMin, pEMax, mrMin, mrMax])

  const getCompanies = async (updatedFilters: any) => {
    let payload: CompaniesListRequest = {};
    if (updatedFilters.market_cap_max !== "") {
      payload.market_cap_max = parseFloat(updatedFilters.market_cap_max);
    }
    if (updatedFilters.market_cap_min !== "") {
      payload.market_cap_min = parseFloat(updatedFilters.market_cap_min);
    }
    if (updatedFilters.pe_ratio_max !== "") {
      payload.pe_ratio_max = parseFloat(updatedFilters.pe_ratio_max);
    }
    if (updatedFilters.pe_ratio_min !== "") {
      payload.pe_ratio_min = parseFloat(updatedFilters.pe_ratio_min);
    }
    try {
      const res = await apiResponse("post", "get-companies", [], payload);

      if (res.data.status === "SUCCESS") {
        const sortedCompanies = orderBy(
          res.data.data,
          ["company_name"],
          ["asc"]
        );
        setCompanies([...sortedCompanies]);
        setFilteredCompanies([...sortedCompanies])
      } else {
        setErrorMeassage(res.data.message);
        setCompanies([]);
        setFilteredCompanies([]);
      }
      setIsRefreshed(false);
      setIsFirstLoading(false);
    } catch (error) {
      setCompanies([]);
      setFilteredCompanies([]);
      setIsRefreshed(false);
      setIsFirstLoading(false);
      setErrorMeassage(apiErrorMessage);
    }
  };

  useEffect(() => {
    setIsFirstLoading(true);
    setCompanies([]);
    setFilteredCompanies([]);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.companies
    ) {
      getCompanies({
        pe_ratio_min: pEMin,
        pe_ratio_max: pEMax,
        market_cap_min: mrMin,
        market_cap_max: mrMax,
      });
    }
  }, []);
  const { authData } = useContext(AppContext);
  return authData && authData?.externalAuthorization?.companies ? (
    <div className="companies-page ops-px-4">
      <div className="ext-companies-filter-section ext-row ops-my-6">
        <div className="ext-col-sm-12 ext-col-xl-4 ops-my-sm-1">
          <div className="ext-row">
            <div className="ext-col-sm-4 ops-pt-sm-2 ops-mb-2 ext-companies-filter-search ext-fw-bold">
              P/E Ratio
            </div>
            <div className="ext-col-sm-8 ops-mt-1">
              <div className="input-group ext-align-items-center ext-display-item-inline ext-col-sm ops-mb-4 ops-mb-md-0">
                <input
                  type="number"
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Min"
                  value={pEMin}
                  onChange={(e) => setPEMin(e.target.value)}
                  onKeyUp={(event) => {
                    setPEMin((event.target as HTMLInputElement).value);
                  }}
                />
                <div className="ops-mx-2 ext-companies-filter-search ext-fw-bold">
                  and
                </div>
                <input
                  type="number"
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Max"
                  value={pEMax}
                  onChange={(e) => setPEMax(e.target.value)}
                  onKeyUp={(event) => {
                    setPEMax((event.target as HTMLInputElement).value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ext-col-sm-12 ext-col-xl-4 ops-my-sm-1">
          <div className="ext-row">
            <div className="ext-col-sm-4 ops-pt-sm-2 ops-mb-2 ops-mb-md-0 ext-companies-filter-search ext-fw-bold">
              Market Cap
            </div>
            <div className="ext-col-sm-8 ops-mt-1 ext-padding-left">
              <div className="input-group ext-align-items-center ext-col-sm ext-display-item-inline">
                <input
                  type="number"
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Min"
                  value={mrMin}
                  onChange={(e) => setMrMin(e.target.value)}
                  onKeyUp={(event) => {
                    setMrMin((event.target as HTMLInputElement).value);
                  }}
                />
                <div className="ops-mx-2 ext-companies-filter-search ext-fw-bold">
                  and
                </div>
                <input
                  type="number"
                  className="form-control input-box ext-input-element-size ext-button-styling" 
                  placeholder="Max"
                  value={mrMax}
                  onChange={(e) => setMrMax(e.target.value)}
                  onKeyUp={(event) => {
                    setMrMax((event.target as HTMLInputElement).value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ext-col-sm-12 ext-col-xl-1 text-decoration-underline ops-pt-sm-2 ops-my-4 ops-my-md-1 ext-fw-bold">
          <span
            className="ext-cursor-pointer ext-reset-button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </span>
        </div>
        <div className="ext-col-xl-3 ops-px-xl-0 ops-mt-md-2 ops-mt-xl-0">
          <div className="ext-status-section ops-pt-sm-2">
            {isRefreshed && (
              <div>
                <Loader />
              </div>
            )}
            <div className="ops-px-xl-0 ops-pe-md-0 ext-fst-italic">
              <span
                className="ext-cursor-pointer "
                onClick={() => {
                  handleReset();
                  getCompanies({
                    pe_ratio_min: "",
                    pe_ratio_max: "",
                    market_cap_min: "",
                    market_cap_max: "",
                  });
                }}
              >
                <RefreshIcon />
              </span>

              {/* Time and View source to be added later      */}
              {/* &nbsp; Market data as of 2:01PM PT */}
            </div>
            {/* <div className=" ops-ps-md-0">
              <div className="vr ops-mx-2 hide-in-mobile" />
              View sources
              <i className="bi bi-info-circle ops-ms-1 ops-mx-2" />
            </div> */}
          </div>
        </div>
      </div>
      {errorMessage !== "" ? (
        <ErrorMessage message={errorMessage} isIcon={false} />
      ) : (
        <div className="companies-list-section ops-p-0 ext-overflow-table">
          <CompanyList
            tableData={filteredCompanies}
            columns={columns}
            isFirstLoading={isFirstLoading}
          />
        </div>
      )}
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default Companies;
