import React, { useEffect, useState } from 'react';
import './index.scss';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../../../utils/routing";
import { Button, Card, Badge } from '@opsdti-global-component-library/amgen-design-system';
import { noDataMessage, apiErrorMessage } from "../../../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";
import { amgenTicker, getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";

export const AmgenInTheNews: React.FC = () => {
    const navigate = useNavigate();
    const currentRoute = useLocation();
    const oktaToken = getOktaToken();
    const [articleList, setArticleList] = useState([]);
    const [articlesLoading, setArticlesLoading] = useState(false);
    const [newsErrorMessage, setNewsErrorMessage] = useState<string>("");

    const getArticleList = async () => {
        try {
          setArticlesLoading(true);
          const payload = {
            company_list: [amgenTicker],
            sort_by_value: "desc",
            page_offset: 0,
            limit: 3,
            filters: { company: [`${amgenTicker}`] },
          };
    
          const res = await axios({
            method: "post",
            url: getApiUrl("get-news-with-tags"),
            data: payload,
            headers: {
              Authorization: `Bearer ${oktaToken}`,
            },
          });
    
          if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
            setArticleList(res.data.data.response);
          } else {
            setArticleList([]);
            setNewsErrorMessage(noDataMessage);
          }
          setArticlesLoading(false);
        } catch (error) {
          setArticleList([]);
          setArticlesLoading(false);
          setNewsErrorMessage(apiErrorMessage);
        }
      };

    useEffect(() => {
      getArticleList();
    }, []);

    return (
        <div className="ext-amgen-news">
            <div className="ext-amgen-news-container">
                <Card title="Amgen in the News">
                <div className="ext-article-list-wrapper ext-card-height ext-border-top-0">
                    {articlesLoading && <Loader />}
                    {!articlesLoading && newsErrorMessage && (
                    <div className="ext-empty-chart-msg">{noDataMessage}</div>
                    )}
                    {articleList && articleList.length > 0 && (
                        <div className="ext-article-content">
                            {articleList.map((article: any, index: number) => (
                                <div key={index} className="ext-article-item">
                                    <div className="ext-badge-title-container">
                                        <Badge count={index + 1}/>
                                        <span className="ext-article-title">{article.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="ext-row ext-page-navigation-wrapper">
                    <Button
                        text="VIEW MORE NEWS ARTICLES"
                        type="secondary"
                        className="ext-page-navigation-footer-btn"
                        onClick={() => {
                            navigate(`${BASE_ROUTE}/news-search`, {
                                state: {
                                    callingPath: currentRoute.pathname
                                },
                            });
                        }}
                    />
                </div>
                </Card>
            </div>
        </div>
    )
}