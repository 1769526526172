import { Dispatch, SetStateAction } from "react";
import { CustomModal } from "../components/modal";
import { Clickthrough } from '@gitlab-rtsensing/component-library'
import { DOMAIN_ROUTES } from "./consts";
import Card from "../components/card";
import './index.scss'
// import QnaFeedback from "./feedback";

type Props = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    question: string;
    answer: string;
    domain: string;
};

const QATileModal = (props: Props) => {
    const {isOpen, setIsOpen, question, answer, domain} = props

    return  (
        <CustomModal
            opened={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            className="qa-modal qa-tile"
        >
            <Card
                cardHeader="Sensing Q&A"
                cardHeaderTag="Experimental"
                isTooltip
                tooltipText="This Q&A capability is powered by OpenAI,
                            so it comes with all of the attendant benefits and limitations. 
                            As such, this output is for illustrative purposes and not yet suitable for decision making."
                tooltipTag="Experimental"
                noOutline
                noTitlePadding={true}
                setIsOpen={setIsOpen}
            >
                <div className="answer-container">
                    <div>
                        <span className="span-header ops-mt-2">
                            <b>Question:&nbsp;</b>
                        </span>
                        {question}
                        <div className="answer-section-modal ops-mt-2">
                            <span className="span-header">
                                <b>Answer:&nbsp;</b>
                            </span>
                            {answer}
                        </div>
                    </div>
                    <div className="ops-mt-2">
                        {domain && 
                            <Clickthrough
                                type="secondary"
                                label={`GO TO ${domain.toUpperCase()}`}
                                href={DOMAIN_ROUTES[domain]}
                                target="_blank"
                                className="submit-button ops-my-1"
                            />
                        }
                    </div>
                </div>
            </Card>
        </CustomModal>
    )
};

export default QATileModal;
