import React, { useState, useEffect, useContext } from "react";
import './index.scss';
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../../../utils/routing";
import { Button, Card, Table, Text } from '@opsdti-global-component-library/amgen-design-system';
import axios from "axios";
import { getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";
import { noDataMessage, apiErrorMessage } from "../../../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AppContext } from '../../../../utils/app-context';

type EarningVsFactSetProps = {
    earningsVsFactSetDataQuarter: string;
    earningsVsFactSetDataYear: string;
    earningsVsFactSetData: any;
    earningsVsFactSetIsLoading: boolean;
    earningsVsFactSetIsError: string;
};

export const EarningsVsFactSet: React.FC<EarningVsFactSetProps> = (props: EarningVsFactSetProps) => {
    const navigate = useNavigate();
    const currentRoute = useLocation();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState("");
    const oktaToken = getOktaToken();
    const { authData } = useContext(AppContext);

    const getEarningsVsFactSetData = async () => {
        const { earningsVsFactSetDataYear: tableDataYear, earningsVsFactSetDataQuarter: tableDataQuarter } = props;
        try {
            const res = await axios({
                method: "get",
                url: `${getApiUrl("get-earning-table-data")}?year=${tableDataYear}&quarter=${tableDataQuarter}`,
                headers: {
                    Authorization: `Bearer ${oktaToken}`,
                },
            });
            if (res?.data?.status === "SUCCESS") {
                setData(res.data.data);
            } else {
                setIsError(noDataMessage);
            }
        } catch (error) {
            setIsError(apiErrorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getEarningsVsFactSetData();
    }, [props.earningsVsFactSetDataQuarter, props.earningsVsFactSetDataYear, oktaToken]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>{isError}</div>;
    }

    const StatusLabel = ({ label }: { label: string }) => {
        const getClassName = (label: string) => {
            if (label === "BEAT") {
                return "ext-earning-beat-flag";
            } else if (label === "MISS" || label === "LOWER") {
                return "ext-earning-miss-flag";
            } else if (label === "IN-LINE") {
                return "ext-earning-reaffirm-flag";
            } else {
                return "";
            }
        };
        
        const className = getClassName(label).toString();

        return (
            <div className="ext-earning-tag-flag ext-d-flex ext-justify-content-center">
                <div className={className}>
                    <Text className="ext-earning-tag-text">{label}</Text>
                </div>
            </div>
        );
    };

    const columns = [
        {
            dataIndex: 'company',
            key: 'company',
            title: 'COMPANY',
            render: (text: string, item: any, index: number) => (
                <div className="ext-earning-company-column">
                    <img className="ext-earning-company-image" src={item.company_image} alt={item.company} />
                    <Text className="ext-earning-company-name"strong>{item.company}</Text>
                </div>
            )
        },
        {
            dataIndex: 'revenue',
            key: 'revenue_beat_miss',
            title: 'REVENUE',
            render: (text: string, item: any, index: number) => (
                <div className="ext-earning-revenue-column">
                    <StatusLabel label={item.revenue} />
                </div>
            )
        },
        {
            dataIndex: 'eps',
            key: 'eps_beat_miss',
            title: 'EPS',
            render: (text: string, item: any, index: number) => (
                <div className="ext-earning-eps-column">
                    <StatusLabel label={item.eps} />
                </div>
            )
        },
    ];

    const [firstItem, ...filteredData] = data as any[];

    const dataSource = filteredData.map((item: any, index: number) => {
        const currentDate = new Date();
        const priceDate = new Date(item.price_date);
        const isFutureDate = priceDate > currentDate;
        const formattedDate = priceDate.toLocaleDateString('en-US');

        return {
            key: index,
            company: item.company_short_name || "N/A",
            company_image: item.company_image || "N/A",
            revenue: isFutureDate ? `Reports on ${formattedDate}` : item.revenue_beat_miss || "N/A",
            eps: isFutureDate ? `Reports on ${formattedDate}` : item.eps_beat_miss || "N/A",
        };
    });

    return (
        <div className="ext-earnings-factset">
            <div className="ext-earnings-factset-container">
                <Card title={`Earnings: Q${firstItem.quarter} ${firstItem.year} (vs. FactSet Consensus)`}>
                    <Table className="ext-earnings-table-wrapper" columns={columns} dataSource={dataSource}/>
                    <div className="ext-row ext-page-navigation-wrapper">
                        {authData?.externalAuthorization?.earnings && (
                                <Button
                                    text="VIEW MORE EARNINGS DATA"
                                    type="secondary"
                                    className="ext-page-navigation-footer-btn"
                                    onClick={() => {
                                        navigate(`${BASE_ROUTE}/earnings`, {
                                            state: {
                                                callingPath: currentRoute.pathname
                                            },
                                        });
                                    }}
                                />
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
};