import React, { useContext, useEffect, useState } from "react";
import Card from "../../components/card";
import ArticleCard from "../../components/article-card-dscu";
import "../oncology/index.scss";
import {
  apiResponse,
  formatKiqName,
} from "../../utils/commonMethods";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { KIQListType } from "../../api/get-kiq-data-dt";
import ErrorMessage from "../../components/error-message";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../components/back-btn";
import { BASE_ROUTE, getBackButtonText } from '../../utils/routing'
import { Paragraph, Title, Pagination } from "@opsdti-global-component-library/amgen-design-system";
import CardFilter from "../../components/cards-filter";
import { SEARCH_TEXT } from "../news-consts";
import { getFormattedTagListWithFilter, Tag, ExctractCompanyNameFromTags } from "../news-utils";

type Props = {
  kiqList: any;
  isLoading: boolean;
  errorMessage: string;
};

const PAGE_LIMIT = 50;
const KIQ: React.FC<Props> = (props: Props) => {
  const [selectedKiqName, setSelectedKiqName] = useState("");
  const [KiqList, setKiqList] = useState<KIQListType[]>([]);

  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(false);
  const [errorMessageNewsList, setErrorMessageNewsList] = useState<string>("");
  const [newsList, setNewsList] = useState([]);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);
  const [paginationDate, setPaginationDate] = useState<string>("");

  const [page, setPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);

  const navigate = useNavigate();
  const location: any = useLocation();
  const { authData } = useContext(AppContext);

  const getKiqDetails = async (pageSelected: number, kiqName: string) => {
    // setSelectedKiqName(kiqName);
    setNewsList([]);
    setIsNewsListLoading(true);
    setErrorMessageNewsList("");
    setTotalArticles(0);

    const payload = {
      sort_by_value: "desc",
      page_offset: pageSelected - 1,
      limit: PAGE_LIMIT,
      filters: { kiq: [`${kiqName}`] },
    };

    try {
      const res = await apiResponse("post", "get-news-with-tags", [], payload);
      if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
        setPaginationDate(res.data.data.ingested_date);
        setNewsList(res.data.data.response);
        setTotalArticles(res.data.data.count);
        setErrorMessageNewsList("");
      } else {
        setNewsList([]);
        setTotalArticles(0);
        setErrorMessageNewsList(noDataMessage);
      }
      setIsNewsListLoading(false);
    } catch (error) {
      setNewsList([]);
      setTotalArticles(0);
      setErrorMessageNewsList(apiErrorMessage);
      setIsNewsListLoading(false);
    }
  };

  const handlePages = async (updatePage: number) => {
    if(page !== updatePage) {
      setPage(updatePage);
      getKiqDetails(updatePage, selectedKiqName);
    }
  }
  const handleClick = (name: string) => {
    if(name !== selectedKiqName) {
      setPage(1);
      setSelectedKiqName(name);
      getKiqDetails(1, name);
    }
  };

  const getUpperLimit = () => {
    const upperLimit = page * PAGE_LIMIT;
    if (upperLimit > totalArticles) {
      return totalArticles;
    }
    return upperLimit;
  };

  const showBtn = () => {
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";

    setShowBackBtn(!pathName.includes(`${BASE_ROUTE}/corporate-strategy` || pathName.includes(`${BASE_ROUTE}/builder`) ));
  };

  useEffect(() => {
    setShowBackBtn(false);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.kiq &&
      props.kiqList.length > 0
    ) {
      setPage(1);

      if (location.state) {
        showBtn();
      } else {
        setBackButtonRoute(-1);
      }

      const kiqs = props.kiqList;
      setKiqList(kiqs);
      const firstKiqCode = kiqs[0];


      const params = window.location.pathname.split("corporate-strategy/");
      const kiqCode = params[1];

      // URL first, else first KIQ name returned from API
      setSelectedKiqName(kiqCode || firstKiqCode.name);
      getKiqDetails(1, kiqCode || firstKiqCode.name);
    }
  }, [location.state]);

  return authData && authData?.externalAuthorization?.kiq ? (
    <div className="ext-oncology">
      <Title level={1}>KIQs</Title>
      {showBackBtn && (
        <div className="kiq-page-navigation ops-mb-2">
          <BackBtn
            navigateFn={() => navigate(backButtonRoute)}
            backBtnText={getBackButtonText(location)}
          ></BackBtn>
        </div>
      )}
      <div className="ext-news-container">
        <div className="ext-news-left-panel">
          <CardFilter>
            <div className="ext-filter-element-container">
              {!props.isLoading &&
                !props.errorMessage &&
                KiqList.map((item: any) => {
                  return (
                    <div
                    onClick={() => {
                      handleClick(item.name);
                    }}
                    className="ext-filter-text"
                    key={item.name}
                  >
                    <Paragraph strong={selectedKiqName == item.name}>
                      {formatKiqName(item.name)}
                    </Paragraph>
                  </div>
                  );
                })}
            </div>
          </CardFilter>
        </div>
        <div className="ext-news-right-panel">
          <Card
            cardHeader={`${formatKiqName(selectedKiqName)} in the News`}
            newsCard={true}
          >
            <div className="ext-article-container">
              <div className="ext-article-sorting-wrapper">
                <Paragraph className="ext-article-card-note">
                  {SEARCH_TEXT}
                </Paragraph>
              </div>
              <div className="ext-article-list-wrapper">
                {isNewsListLoading && (
                  <div
                    className="ext-spinner-style ext-d-flex ext-justify-content-center ext-topic-loader"
                    role="status"
                  >
                    <Loader />
                  </div>
                )}
                {!isNewsListLoading && errorMessageNewsList && (
                  <ErrorMessage isIcon={false} message={errorMessageNewsList} />
                )}
                {!isNewsListLoading &&
                  !errorMessageNewsList &&
                  newsList?.map((article: any, index: any) => {
                    const isEven =
                      index % 2 === 0

                  const tags: Array<Tag> = getFormattedTagListWithFilter(
                    {
                      "company_names": ExctractCompanyNameFromTags(article.company_names),
                      "product_names": article.product_names,
                      "topic_names": article.topic_names,
                      "corex_topic_names": article.corex_topic_names,
                      "kiq_names": article.kiq_names,
                      "av_company_names": article.av_company_names,
                    }
                  )

                    return (
                      <ArticleCard
                        isEven={isEven}
                        key={index}
                        redirectUrl={article.url}
                        title={article.title}
                        articleId={article.article_id}
                        publishDate={article.published}
                        publishSource={article.source_name}
                        tagList={tags}
                        checkboxFlag={false}
                        trashFlag={false}
                        tagType={'BASIC'}
                      />
                    );
                  })}
            </div>

            <div className="ext-article-pagination">
              <div className="pagination-container">
                  {!isNewsListLoading &&
                    newsList.length !== 0 &&
                    totalArticles !== 0 && (
                      <div className="ext-article-pagination-wrapper">
                       <Paragraph>
                          Last database update at {paginationDate?.replace("T", "T on")}
                        </Paragraph>
                        <Pagination
                          showSizeChanger={false}
                          showTotal={(total:any) => `${(page - 1) * PAGE_LIMIT + 1} - ${getUpperLimit()} of ${totalArticles} items`}
                          current={page}
                          total={totalArticles || 0}
                          pageSize={PAGE_LIMIT}
                          onChange={(page: number) => handlePages(page)}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default KIQ;
