/* eslint-disable prefer-const */
/* eslint-disable import/no-unresolved */
/*eslint prefer-const: "error"*/
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import "./ExternalSensing.scss";
import { BrowserRouter } from "react-router-dom";
import ExternalRoutes from "./components/external-routes";

const ExternalSensing: React.FC = (props: any) => {
  return (
    <div className="external-sensing-wrapper">
      <div className="external-sensing-container">
        <BrowserRouter>
          <ExternalRoutes props={props} />
        </BrowserRouter>
      </div>
    </div>
  );
};

export default ExternalSensing;
