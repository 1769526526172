import React, { useEffect, useState, useContext } from 'react';
import './index.scss';
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import { noDataMessage, apiErrorMessage } from "../../assets/errorMessageConstants";
import { MoreIntelligence } from './components/more-intelligence';
import { GPTNews } from './components/gpt-news';
import { Title } from '@opsdti-global-component-library/amgen-design-system';
import { GPT_NEWS_TABS, GPT_COMPETITOR_NEWS_TABS_DEFAULT, NewsTabs, Company } from './consts';
import { apiResponse } from '../../utils/commonMethods';
import { AppContext } from '../../utils/app-context';
import { AmgenInTheNews } from './components/amgen-in-the-news';
import { EarningsVsFactSet } from './components/earnings-and-factset';
import { Loader } from "@gitlab-rtsensing/component-library";
import {ComparativePEValues} from './components/comparative-pe';

const HomePage: React.FC = () => {
  const [competitorNewsTabs, setCompetitorNewsTabs] = useState<NewsTabs>([])
  const { authData } = useContext(AppContext);
  const getCompanysList = async () => {
    try {
      const res = await apiResponse("get", "get-company-list", [], {});
      if (res?.data.data) {
        const companyList = res.data.data
        const tabMap = companyList.map((company: Company) => {
          return {
            key: company.name,
            label: company.name,
            filters: {
              company: [company.ticker]
            }
          }
        })
        const tickerList: string[] = companyList.map((company: Company) => company.ticker)
        const tabMapWithAll = [{
          key: 'All',
          label: 'All',
          filters: {
            company: tickerList
          }
        },
          ...tabMap
        ]
        setCompetitorNewsTabs(tabMapWithAll);
      }
    } catch (error) {
      console.log('SensingGPT Competitor News Fetch Failed', error)
      // Setting defaults if fetch fails
      setCompetitorNewsTabs(GPT_COMPETITOR_NEWS_TABS_DEFAULT)
    }
  };

  useEffect(() => {
    getCompanysList()
  }, [])
  const [earningsYear, setYear] = useState<string>("");
  const [earningsQuarter, setQuarter] = useState<string>("");
  const [earningsVsFactSetData, setEarningsVsFactSetData] = useState([]);
  const [earningsVsFactSetIsLoading, setIsLoading] = useState(true);
  const [earningsVsFactSetIsError, setIsError] = useState("");
  const oktaToken = getOktaToken();

  const getYearAndQuarter = async () => {
    try {
        const res = await axios({
            method: "get",
            url: `${getApiUrl("time-scale")}`,
            headers: {
                Authorization: `Bearer ${oktaToken}`,
            },
        });
        if (res && res.data && res.data.status === "SUCCESS") {
          const years = Object.keys(res.data.data).map(year => parseInt(year, 10));
          const currentYear = Math.max(...years).toString();
          const currentQuarter = res.data.data[currentYear][0].replace("Q", "");  
          setYear(currentYear);
          setQuarter(currentQuarter);
          getEarningsVsFactSetData(currentYear, currentQuarter);
        } else {
            setIsError(noDataMessage);
        }
    } catch (error) {
        setIsError(apiErrorMessage);
    } finally {
      setIsLoading(false);
  }
  };

  const getEarningsVsFactSetData = async (year: string, quarter: string) => {
    try {
        const res = await axios({
            method: "get",
            url: `${getApiUrl("get-earning-table-data")}?year=${year}&quarter=${quarter}`,
            headers: {
                Authorization: `Bearer ${oktaToken}`,
            },
        });
        if (res && res.data && res.data.status === "SUCCESS") {
            setEarningsVsFactSetData(res.data.data);
        } else {
            setIsError(noDataMessage);
        }
    } catch (error) {
        setIsError(apiErrorMessage);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    getYearAndQuarter();
  }, [oktaToken]);

  useEffect(() => {
    if (earningsYear && earningsQuarter) {
      getEarningsVsFactSetData(earningsYear, earningsQuarter);
    }
  }, [earningsYear, earningsQuarter]);

  if (earningsVsFactSetIsLoading) {
    return <Loader />;
  }

  if (earningsVsFactSetIsError) {
    return <div>{earningsVsFactSetIsError}</div>;
  }
  
  return (
    <div className="ext-home-container ext-row">
      <Title style={{marginBottom: '8px'}} level={2}>Amgen Market Update</Title>
      <div className='ext-row'>
        <div className='ext-col-8'> 
          {/* AMGN Stock Performance Card */}
        </div>
        <div className='ext-col-4'>
          <ComparativePEValues/>
        </div>
      </div>
      <Title style={{marginBottom: '8px'}} level={2}>Latest News</Title>
      <div className='ext-row'>
        <div className='ext-col-8'>
          <GPTNews title='SensingGPT Headlines' tabs={GPT_NEWS_TABS} />
        </div> 
        <div className='ext-col-4'>
          <EarningsVsFactSet earningsVsFactSetDataQuarter={earningsQuarter} earningsVsFactSetDataYear={earningsYear} earningsVsFactSetData={earningsVsFactSetData} earningsVsFactSetIsLoading={earningsVsFactSetIsLoading} earningsVsFactSetIsError={earningsVsFactSetIsError}/>  
        </div>
      </div>
      <div className='ext-row'>
        <div className='ext-col-8'>
          {authData?.externalAuthorization?.companies && competitorNewsTabs.length > 0 && <GPTNews title='SensingGPT Competitor News Headlines' tabs={competitorNewsTabs} />}
        </div>
        <div className='ext-col-4'>
          <AmgenInTheNews/>
        </div>
      </div>
      <MoreIntelligence/>
    </div>
  );
};

export default HomePage;