import './index.scss';
import { useContext } from 'react'
import { BASE_ROUTE } from "../../../../utils/routing";
import { Link, Title, Paragraph, Skeleton, ThemeContext } from '@opsdti-global-component-library/amgen-design-system';
import { NewsArticle } from '../../consts';
import parse from 'html-react-parser';

type Props = {
  news: NewsArticle[];
  isNewsLoading: boolean;
  isNewsError: string;
}

export const NewsList = ({news, isNewsLoading, isNewsError}: Props) => {
  const { theme } = useContext(ThemeContext);
  const { colorTextSecondary, colorBorderSecondary } = theme?.token as any;
  const onLinkClick = (articleId: string, url?: string) => {
    if (url === "" || url === null) {
      const finalUrl = `${BASE_ROUTE}/news/${articleId}.html`;
      window.open(finalUrl);
      return;
    }
    const finalUrl = url?.replace(/^http:\/\//i, "https://");
    window.open(finalUrl);
  };
  const getmodifiedContent = (data: string) => { // literal magic used on our other components :(
    const regex = /(- |\n- )/g
    const finalData = data && data.replace(regex, ' ');
    return <Paragraph>{parse(finalData)}</Paragraph>
  }

  if(isNewsError !== '') {
    return (
      <div className='gpt-news-list-container'>
        <Paragraph>{isNewsError}</Paragraph>
      </div>
    )
  }
  return (
    <Skeleton title={false} paragraph={{rows: 12}} loading={isNewsLoading}>
      <div style={{borderBottom: `1px solid ${colorBorderSecondary}`, paddingBottom: '8px'}}>
        {news && news.map((article, index: number) => {
            return (
              <div key={index} className='gpt-news-list-row-container'>
                  <div style={{color: colorTextSecondary}} className='gpt-news-list-bullet'>∙</div>
                  <div>
                    <div onClick={() => onLinkClick(article.article_id, article.url)}>
                      <Title className='gpt-news-list-container' level={3}>
                        <Link className="gpt-news-list-title" type="secondary">
                            {article.title}
                        </Link>
                      </Title>
                    </div>
                    <Paragraph className="gpt-news-list-content">
                      {article.article_summary && getmodifiedContent(article.article_summary)}
                    </Paragraph>
                  </div>
              </div>
            )
          })
        }
      </div>
    </Skeleton>
  );
};
