import {
  formatText
} from "../utils/commonMethods";

type NewsCurrentlyFilteredList = {
  [key: string]: Array<string>
}

export type Tag = {
  name: string
  type: string
  onClick: () => void
  onFilter: () => void
  isFilteredOnTag: boolean
}

const getFormattedTags = (
  list: Array<string>, // Article's tags domain level
  filteredList: Array<string>, // Currently Filtered domain level
  listDisplayNameOverride: Array<string> | null, // Display Names for Tags by domain
  type: string, //Domain
  onFilter: (tag: any) => void,
  onClick: (tag: any) => void,
): Tag[] => {
  const onTagFiltered = (tagId: string) => {
    const value = {
      name: tagId,
      field: type,
      isChecked: !filteredList.includes(tagId),
    };
    onFilter(value);
  };

  const tagData: Tag[] = [];
  list?.forEach((tagName: string, i: number) => { //Domain Level
    let tag_display = tagName;
    if(listDisplayNameOverride !== null && listDisplayNameOverride !== undefined && listDisplayNameOverride.length > 0){
      tag_display = listDisplayNameOverride[i]
    }
    let isFilteredOnTag = false;
    if (filteredList.length > 0) {
      isFilteredOnTag = filteredList.includes(tagName)
    }
    const tag: Tag = {
      name: formatText(tag_display),
      type: type,
      onClick: () => {
        onClick(tagName);
      },
      onFilter: () => {
        onTagFiltered(tagName);
      },
      isFilteredOnTag: isFilteredOnTag,
    };
    tagData.push(tag);
  });
  return tagData;
};
  
export const getFormattedTagListWithFilter = (
  tagList: NewsCurrentlyFilteredList, // Contains ARTICLES Tags by domain
  displayNamesOverrideByDomain?: NewsCurrentlyFilteredList,  // Contains Display Names for ARTICLE Tags by domain
  currentlyFiltered?: NewsCurrentlyFilteredList, // Contains Currently Checked Filters by domain
  onFilter?: (value: any) => void, // Changes for a domain
  onClick?: (value: any) => void
) => {
  const tags: any = [] // NEEDS TO TYPE
  let displayOverrides: Array<string> | null;
  Object.keys(tagList).forEach((domain: string) => {
    if(displayNamesOverrideByDomain) displayOverrides = displayNamesOverrideByDomain[domain] || null
    const DomainTagList = getFormattedTags(
      tagList[domain],
      currentlyFiltered ? currentlyFiltered[domain] : [],
      displayOverrides,
      domain,
      onFilter ? onFilter : () => {},
      onClick  ? onClick  : () => {},
    )
    tags.push(...DomainTagList)
  })

  return tags;
};

export const ExctractCompanyNameFromTags = (company_tags:Array<string>) => {
  if(!company_tags) return []
  return company_tags.map((tag: string) => {
    return tag.split("$$")[1]
  })
}