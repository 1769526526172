import { BASE_ROUTE } from "../utils/routing"

export type RadioOptions = {
    [key: string]: Array<string>,
}

type DomainRoutes = {
    [key: string]: string
}
export const DOMAIN_ROUTES: DomainRoutes = {
    "News": `${BASE_ROUTE}/news-search`,
    "Supply": "/supply/dashboard",
    "Pipeline": "/pipeline",
    "Finance": "/finance",
    "Manufacturing": "/supply/manufacturing",
    "Brand": "/brand"
}

export const RADIO_OPTIONS: RadioOptions = {
    "News": [
        "Summarize the most recent news about Amgen from the past two weeks.",
        "What are the latest developments on Novo's Wegovy, Lilly's Mounjaro, and Zepbound?",
        "Could you provide the latest updates on Sanofi's Generative AI initiatives in Biotech?"
    ],
    "Supply": [
        "Are there any commercial products at risk within product advancement?",
        "Can you provide a summary of recent product complaints?",
        "Which product SKUs are currently below the minimum target for Finished Drug Products?"
    ],
    "Finance": [
        "Please provide me with a summary of the latest projections for the current year.",
        "What was Amgen’s total budget allocated for clinical trials last quarter, and how did actual spending compare?",
        "How are product sales trending this quarter compared to the projections made at the beginning of the year?"
    ],
    "Pipeline": [
        "What are the latest developments regarding MariTide?",
        "Provide me with the latest projections for Imdelltra",
        "What are the most critical milestones expected across the portfolio in the next six months?"
    ],
    "Manufacturing": [
        "What was our Process Lead Time for last quarter?",
        "Why does abnormal scrap need attention?",
        "What is the trend of Safety-Recordable Injury Rate?"
    ],
    "Brand": [
        "What are the year-to-date sales for Repatha in the US?",
        "Which brands have the worst year-to-date global net sales variance?",
        "What is the trend for Tezspire's US demand units volume?"
    ],
}