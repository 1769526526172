import { Dispatch, SetStateAction, useRef, useState } from "react";
import { DOMAIN_ROUTES } from "./consts";
import { Button, Loader, Clickthrough } from '@gitlab-rtsensing/component-library'
import { useIsOverflow } from "../utils/useOverflow";
import "./index.scss";
import QnaModal from './modal'
import QnaFeedback from "./feedback";

interface AnswerViewProps {
    question: string;
    answer: string;
    domain: string;
    questionId?: string;
    answerLoading: boolean;
    setInitialView:  Dispatch<SetStateAction<boolean>>;
}

const AnswerView = (props: AnswerViewProps) => {
    const answerSection = useRef<HTMLInputElement>(null)
    const [isModalOpen, setIsModalOpen ] =  useState<boolean>(false)
    const { question, answer, domain, answerLoading, questionId, setInitialView } = props
    const isOverflow = useIsOverflow(answerSection, [answer, answerLoading])

    return (
        <div className="answer-container">
            <div>
                <span className="span-header">
                    <b>Question:&nbsp;</b>
                </span>
                {question}
                <div ref={answerSection} className="answer-section">
                    <span className="span-header">
                        <b>Answer:&nbsp;</b>
                    </span>
                    {answerLoading 
                    ? <div className="loader"> <Loader /></div> 
                    : answer}
                </div>
            </div>
            <div>
                {questionId !== undefined && <QnaFeedback questionId={questionId} />}
                {isOverflow && 
                    <Button
                        type="secondary"
                        label="VIEW MORE"
                        onClick={() => setIsModalOpen(true)}
                        className="submit-button ops-my-1 ops-mt-2"
                    />
                }
                <Button
                    type="primary"
                    label="ASK NEW QUESTION"
                    onClick={() => setInitialView(true)}
                    className="submit-button submit-button-primary ops-my-1"
                />
                {domain && 
                    <Clickthrough
                        type="secondary"
                        label={`GO TO ${domain.toUpperCase()}`}
                        href={DOMAIN_ROUTES[domain]}
                        className="submit-button ops-my-1"
                        target="_blank"
                    />
                }

            </div>
            <QnaModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                domain={domain} 
                question={question}
                answer={answer}
            />
        </div>
    );
};

export default AnswerView;
