//create sample react component with named earningtable in typescript
import React, {useState, useContext} from "react";
import "./index.scss";

//image imports
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import { Popover } from '@opsdti-global-component-library/amgen-design-system';
import InfoIcon from "../../assets/icons/info-icon";
import InsightIcon from "../../assets/icons/insight-icon";
import EditIcon from "../../assets/icons/edit-icon";
import EarningModal from "../earning-modal";
import { AppContext } from "../../utils/app-context";
import { CustomModal } from "../modal";
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { noDataMessage, apiErrorMessage } from "../../assets/errorMessageConstants";
import { getOktaToken } from "../../utils/commonMethods";

type EarningTableProps = {
  isLoading: boolean;
  data: any;
  isError: string;
  isExpandTable: boolean;
  tableHeaders: any;
  timeScaleData?: any;
  getTableData?: any;
  keysForComparisonData?: any;
  getEarningsComparativeAnalysisData?: any;
  tableDataQuarter: string;
  tableDataYear: string
};

const EarningTable: React.FC<EarningTableProps> = (
  props: EarningTableProps
) => {

  const {isLoading, data, tableHeaders, isExpandTable, timeScaleData, getTableData, keysForComparisonData ,getEarningsComparativeAnalysisData, tableDataQuarter, tableDataYear} = props;
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenEarningCompanyModal, setIsOpenEarningCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companyInsights, setCompanyInsights] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [editableMetricsData, setEditableMetricsData] = useState([]);
  const [editableMetricsDataError, setEditableMetricsDataError] = useState("");
  const [editableMetricsDataLoading, setEditableMetricsDataLoading] = useState(false);
  const [editableMetricsPreviousData, setEditableMetricsPreviousData] = useState([]);
  const [editableMetricsPreviousDataError, setEditableMetricsPreviousDataError] = useState("");
  const [editableMetricsPreviousDataLoading, setEditableMetricsPreviousDataLoading] = useState(false);
  
  const { authData } = useContext(AppContext);
  const oktaToken = getOktaToken();

  const getCurrencyIcon = (currency: string) => {
    if(currency == "US Dollar") return '$';
    if(currency == "Euro") return '€';
    if(currency == "Pound Sterling") return '£';
    if(currency == "Swiss Franc") return 'CHF';
    if(currency == "Danish Krone") return 'DKK';
  }

  const getData = (label: string) => {
    if(label.length ===0) {
      return <div className="ext-earning-tooltip-box">N/A <span className="ops-mt-1"><span>
      <Popover
        id="tooltip"
        data-html={true}
        content={"Data not available in FactSet"}
      >
        <a data-tooltip-id="tooltip">
          <InfoIcon fill="#9C9C9C" />
        </a>
      </Popover>
    </span></span></div>
    }
    else {
    return (
      <div className="ext-earning-tag-flag ext-d-flex ext-justify-content-center">
        <div
          className={
            label === "MISS" || label === "LOWER" 
            ?
            "ext-earning-miss-flag" 
            :
            label === "IN-LINE"
            ?
            "ext-earning-reaffirm-flag"
            :
            "ext-earning-beat-flag"
          }
        >
          <span className="ext-earning-tag-text">{label}</span>
        </div>
      </div>
    );
    }
  };

  const numberFormatter = (data:any, toFixed:any) => {
    let updated_number:any = "";
      if(!String(data).includes(".")){
        updated_number = data.toFixed(toFixed);
      }else if (String(data).includes(".")){
        let num_arr:any = String(data).split(".");
        let no_dec_place:any = num_arr[1].length;
        if(no_dec_place > toFixed){
          const updated_dec = num_arr[1].substring(0, no_dec_place - (no_dec_place - toFixed));
          num_arr[1] = updated_dec
        }else if(no_dec_place < toFixed){
          const updated_dec = String(num_arr[1]) + "0".repeat(toFixed - no_dec_place)
          num_arr[1] = String(updated_dec)
        }
        updated_number = String(num_arr[0]) + "." + String(num_arr[1])
      }
    return updated_number;
  }

  const getDataWithToolTip = (data: any, toFixed:number) => {
    const finalOutput = numberFormatter(data, toFixed)
    return (finalOutput +'%')
  }

  const getDataWithToolTipValue = (data: number, currency: string, toFixed:number) => {    
    const finalOutput = numberFormatter(data, toFixed)
    return <span>{getCurrencyIcon(currency)}{finalOutput}</span>
  }

  const getColoredLabel = (Label:string) => {
    if(Label.length ===0) {
      return <div className="ext-earning-tooltip-box">N/A <span className="ops-mt-1"><span>
      <Popover
        id="tooltip"
        data-html={true}
        content={"Data not available in FactSet"}
      >
        <a data-tooltip-id="tooltip">
          <InfoIcon fill="#9C9C9C" />
        </a>
      </Popover>
    </span></span></div>
    }
    else {
    return (
      <div className="ext-earning-tag-flag ext-d-flex ext-justify-content-center">
        <div
          className={(Label === "RAISE" || Label === "RAISED" ) 
            ? 'ext-earning-beat-flag' 
            : Label === "REAFFIRM" || Label === "REAFFIRMED" || Label === "NARROWED" || Label === "WIDENED"
            ? "ext-earning-reaffirm-flag" 
            : 'ext-earning-miss-flag'}
        >
          <span className="ext-earning-tag-text">{Label}</span>
        </div>
      </div>
    );
    }
  }

  const getFinancialEditableMetricsPreviousYearData = async (item: any, quarter: any = tableDataQuarter, year: any = tableDataYear) => {
    setEditableMetricsPreviousDataLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-financial-editable-metrics")}?company_ticker=${item?.company_ticker}&year=${Number(year) - 1}&quarter=${quarter}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setEditableMetricsPreviousData(res.data.data);
      } else {
        setEditableMetricsPreviousDataError(noDataMessage);
        setEditableMetricsPreviousData([]);
      }
      setEditableMetricsPreviousDataLoading(false);
    } catch (error) {
      setEditableMetricsPreviousDataLoading(false);
      setEditableMetricsPreviousDataError(apiErrorMessage);
      setEditableMetricsPreviousData([]);
    }
  };

  const getFinancialEditableMetricsCurrentYearData = async (item: any, quarter: any = tableDataQuarter, year: any = tableDataYear) => {
    setEditableMetricsDataLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-financial-editable-metrics")}?company_ticker=${item?.company_ticker}&year=${year}&quarter=${quarter}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setEditableMetricsData(res.data.data);
      } else {
        setEditableMetricsDataError(noDataMessage);
        setEditableMetricsData([]);
      }
      setEditableMetricsDataLoading(false);
    } catch (error) {
      setEditableMetricsDataLoading(false);
      setEditableMetricsDataError(apiErrorMessage);
      setEditableMetricsData([]);
    }
  };

  const editCompanyDetails = (item: any) => {
    setSelectedCompany(item);
    getFinancialEditableMetricsCurrentYearData(item, tableDataQuarter, tableDataYear);
    getFinancialEditableMetricsPreviousYearData(item, tableDataQuarter, tableDataYear);
    setIsOpenEditModal(true);
  }

  const companyInsghtDetails = (item: any) => {
    setSelectedCompanyName(item.company_short_name)
    setCompanyInsights(item.notesSuperSet);
    setIsOpenEarningCompanyModal(true);
  }


  const getCompanyData = (item:any, index: number) => {
    return (
      <div className="ext-d-flex ext-justify-content-between">
        <div className="ext-d-flex">
        <div className="ext-earning-table-company-logo">
          <img src={item.company_image} className="ext-table-company-icon" />
        </div>
        <div className="ext-earning-table-company-name">{item.company_short_name} </div>
      </div>
        <div className="ext-log ops-mx-3 ops-mt-2">
          {item.notesSuperSet && item.notesSuperSet.length > 0 && <span className="ops-mx-1 ext-cursor-pointer" onClick={() => companyInsghtDetails(item)} ><InsightIcon /></span>}
          {authData?.externalAuthorization?.earningsFinancialSummary && <span className="ops-mx-1 ext-cursor-pointer" onClick={() => editCompanyDetails(item)}><EditIcon /></span>}
        </div>
      </div>
    );
  }

  const onCloseEarningModal = () => {
    setIsOpenEditModal(false);
    setEditableMetricsData([]);
    setEditableMetricsPreviousData([]);
  }

  return (
      isLoading ? <div className="ext-earning-table-loader"   ><Loader/></div> :
    <div className="ops-mx-3 ext-aearning-table-container-width">
      <table
        className="ext-earning-table-continer ext-text-center"
        style={isExpandTable ? { width: "1950px" } : { width: "100% " }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
      >
        { isExpandTable ?
        <>
        <tr className="ext-earning-table-header">
          <th
            rowSpan={parseInt("2")}
            className="ext-earning-table-header-company ext-text-left"
          >
            <div className="ext-d-flex ext-earning-table-company">
              Company
            </div>
          </th>
          <th
            colSpan={parseInt("3")}
            className="ext-earning-table-header-markry ext-border-right-header"
          >
            Select Market Metrics
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-financial ext-border-right-header"
          >
           {tableHeaders.quarter} {tableHeaders.year} Financials in reporting currency
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-financial ext-border-right-header"
          >
            {tableHeaders.quarter} {tableHeaders.year}  Select Financial Metrics
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-growth ext-border-right-header"
          >
            {tableHeaders.quarter} {tableHeaders.year}  YOY Growth
          </th>
          <th
            colSpan={parseInt("4")}
            className="ext-earning-table-header-facet ext-border-right-header"
          >
            {tableHeaders.quarter} {tableHeaders.year}  Beat/Miss (vs. FactSet Consensus)
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-facet ext-border-right-header"
          >
            FY {tableHeaders.quarter === "Q4" || tableHeaders.quarter === "FY"  ? tableHeaders.year + 1 : tableHeaders.year } Guidance Update
          </th>
        </tr>
        <tr className="ext-earning-table-header">
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              PRICE <br /> ({tableHeaders.price_date})
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              PRICE CHANGE <br /> ({tableHeaders.price_date})
            </div>
          </td>
          <td  className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              MARKET CAP
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
          <td >
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
            OP MARGIN % SALES 
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              TAX RATE
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              {"REVENUE (VAR %)"}
            </div>
          </td>
          <td  className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              {"EPS (VAR %)"}
            </div>
          </td>
          <td >
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
        </tr>
         {
          data.map((item:any, index:number) => {
            return (
              <tr className="ext-earning-table-row" key={index}>
              <td className="ext-text-left ext-border-bottom">{getCompanyData(item, index)}</td>
              <td className="ext-border-bottom">${numberFormatter(item.price, 2)}</td>
              <td className={item.raw_price_ytd < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{numberFormatter(item.raw_price_ytd, 2)}%</td>
              <td className="ext-box-shadow-right ext-border-bottom">${numberFormatter(item.market_cap, 1)}B</td>
              {
                item && item?.message === "" ?
                <>
              <td className={item.currency_revenue < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{getDataWithToolTipValue(item.currency_revenue, item.currency, 1)}{item.currency_revenue != 0 ? "B" : ""}</td>
              <td className={item.currency_eps < 0 ? "ext-red-color ext-box-shadow-right ext-border-bottom" : "ext-box-shadow-right ext-border-bottom"}>{getDataWithToolTipValue(item.currency_eps, item.currency, 2)}</td>
              <td className={item.core_op_margin_sales < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{getDataWithToolTip(item.core_op_margin_sales, 1)}</td>
              <td className={item.tax_rate < 0 ? "ext-red-color ext-box-shadow-right ext-border-bottom" : "ext-box-shadow-right ext-border-bottom"}>{getDataWithToolTip(item.tax_rate, 1)}</td>
              <td className={item.revenue_yoy < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{getDataWithToolTip(item.revenue_yoy, 1)}</td>
              <td className={item.eps_yoy < 0 ? "ext-red-color ext-box-shadow-right ext-border-bottom" : "ext-box-shadow-right ext-border-bottom"}>{getDataWithToolTip(item.eps_yoy, 1)}</td>
              <td className="ext-earning-tag-column ext-border-bottom">{getData(item.revenue_beat_miss)}</td>
              <td className="ext-earning-tag-column ext-border-bottom">{getData(item.eps_beat_miss)}</td>
              <td className={item.revenue_variation < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"} >{getDataWithToolTip(item.revenue_variation, 1)}</td>
              <td className={item.eps_variation < 0 ? "ext-red-color ext-box-shadow-right ext-border-bottom" : "ext-box-shadow-right ext-border-bottom"}>{getDataWithToolTip(item.eps_variation, 1)}</td>
              <td className="ext-border-bottom ext-border-bottom">{getColoredLabel(item.fy_revenue_guidance)}</td> 
              <td className="ext-border-bottom">{getColoredLabel(item.fy_eps_guidance)}</td>
              </> : 
              <td colSpan={12} className="ext-earning-table-no-data">{item?.message}</td>
              }
            </tr>
            )
          })
        } </>: <>
        <tr className="ext-earning-table-header">
          <th
            rowSpan={parseInt("2")}
            className="ext-earning-table-header-company ext-text-left"
          >
            <div className="ext-d-flex">
              Company
            </div>
          </th>

          <th
            colSpan={parseInt("3")}
            className="ext-earning-table-header-markry ext-border-right-header"
          >
            Select Market Metrics
          </th>
          
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-financial ext-border-right-header"
          >
            {tableHeaders.quarter} {tableHeaders.year} Financials in reporting currency
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-facet ext-border-right-header"
          >
            {tableHeaders.quarter} {tableHeaders.year}  Beat/Miss (vs. FactSet Consensus)
          </th>
          <th
            colSpan={parseInt("2")}
            className="ext-earning-table-header-facet ext-border-right-header"
          >
            FY {tableHeaders.quarter === "Q4" || tableHeaders.quarter === "FY"  ? tableHeaders.year + 1 : tableHeaders.year } Guidance Update
          </th>
        </tr>
        <tr className="ext-earning-table-header">
        <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              PRICE <br /> ({tableHeaders.price_date})
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              PRICE CHANGE <br /> ({tableHeaders.price_date})
            </div>
          </td>
          <td  className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              MARKET CAP
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
          <td>
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
          <td >
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              REVENUE
            </div>
          </td>
          <td className="ext-border-right-header">
            <div className="ext-d-flex ext-w-100 ext-justify-content-center">
              EPS
            </div>
          </td>
        </tr>
         {
          data.map((item:any, index:number) => {
            return (
              <tr className="ext-earning-table-row" key={index}>
              <td className="ext-text-left ext-border-bottom">{getCompanyData(item, index)}</td>
              <td className="ext-border-bottom">${numberFormatter(item.price, 2)}</td>
              <td className={item.raw_price_ytd < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{numberFormatter(item.raw_price_ytd, 2)}%</td>
              <td className="ext-box-shadow-right ext-border-bottom">${numberFormatter(item.market_cap, 1)}B</td>
              {
                item && item?.message === "" ?
                <>
              <td className={item.currency_revenue < 0 ? "ext-red-color ext-border-bottom" : "ext-border-bottom"}>{getDataWithToolTipValue(item.currency_revenue, item.currency, 1)}{item.currency_revenue != 0 ? "B" : ""}</td>
              <td className={item.currency_eps < 0 ? "ext-red-color ext-box-shadow-right ext-border-bottom" : "ext-box-shadow-right ext-border-bottom"}>{getDataWithToolTipValue(item.currency_eps, item.currency, 2)}</td>
              <td className="ext-earning-tag-column ext-border-bottom">{getData(item.revenue_beat_miss)}</td>
              <td className="ext-earning-tag-column ext-border-bottom ext-box-shadow-right">{getData(item.eps_beat_miss)}</td>
              <td className="ext-border-bottom ext-box-shadow-right">{getColoredLabel(item.fy_revenue_guidance)}</td>
              <td className="ext-border-bottom">{getColoredLabel(item.fy_eps_guidance)}</td>
              </>
              : 
              <td colSpan={10} className="ext-earning-table-no-data">{item?.message}</td>
              }
            </tr>
           
            )
          })
        } 
        </>

      }
      </table>

      <EarningModal 
        isOpenEarningModal={isOpenEditModal} 
        selectedCompany={selectedCompany} 
        closedEarningModal={onCloseEarningModal} 
        timeScaleData={timeScaleData} 
        editableMetricsData={editableMetricsData}
        editableMetricsDataError={editableMetricsDataError}
        editableMetricsDataLoading={editableMetricsDataLoading}
        editableMetricsPreviousData={editableMetricsPreviousData}
        editableMetricsPreviousDataError={editableMetricsPreviousDataError}
        editableMetricsPreviousDataLoading={editableMetricsPreviousDataLoading}
        selectedQuarter={tableDataQuarter}
        selectedYear={tableDataYear}
        getFinancialEditableMetricsPreviousYearData={getFinancialEditableMetricsPreviousYearData}
        getFinancialEditableMetricsCurrentYearData={getFinancialEditableMetricsCurrentYearData}
        getTableData={getTableData}
        keysForComparisonData={keysForComparisonData}
        getEarningsComparativeAnalysisData={getEarningsComparativeAnalysisData}
      />

      <CustomModal opened={isOpenEarningCompanyModal} onClose={() => {setIsOpenEarningCompanyModal(false); setCompanyInsights([]); setSelectedCompanyName("")}}>
        <CustomModal.Header>
          <CustomModal.Title title={`${selectedCompanyName} Earnings Insights` }/>
      </CustomModal.Header>
      <div className="ext-earning-details-modal">{
        companyInsights.length > 0 && companyInsights.map((item: any, index: number) => {
          return ( <div className="ext-earning-deatails-content ops-my-2" key={index}>{item.noteContent}</div>)
        })
        }
      </div>
      <div className="ext-w-100 ops-my-1">
          <Button
            label="CANCEL"
            onClick={() => {setIsOpenEarningCompanyModal(false)}}
            type="secondary"
            className="ext-earning-view-button"
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default EarningTable;
