/* eslint-disable no-mixed-operators */
import LeftIcon from "../../assets/icons/left-icon";
import RightIcon from "../../assets/icons/right-icon";
import "./index.scss";
  
  type PaginationHeaderProps = {
    handlePageChange: (action: string) => void;
    totalProducts: number;
    paginationNumber: {
      startNumber: number;
      endNumber: number;
    };
  };
  
  function PaginationHeader(props: PaginationHeaderProps) {
    const { handlePageChange, totalProducts, paginationNumber } = props;
  
    const getPaginationText = () => {
      return `SHOWING ${paginationNumber.startNumber} ${
        window.innerWidth > 464 ? "-" : ""
      } ${
        window.innerWidth > 464 ? paginationNumber.endNumber : ""
      } OF ${totalProducts}`;
    };
  
    return (
      <div className="brand-header-content brand-mb-2 ops-mx-3 ext-d-flex">
        <span className="brand-page-number">{getPaginationText()}</span>
        <div
          className="brand-pagination-btn brand-pagination-btn-first"
          onClick={() => {
            handlePageChange("Decrement");
          }}
        ><LeftIcon fill="#0063c3" />
        </div>
        <div
          className="brand-pagination-btn"
          onClick={() => {
            handlePageChange("Increment");
          }}
        >
          <RightIcon fill="#0063c3" />
        </div>
      </div>
    );
  }
  
  export default PaginationHeader;
  